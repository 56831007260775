<template>
  <div class="pageContol listWrap templateList addClassCtr">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">新增班级</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="padding-bottom: 20px">基本信息</h3>
            <el-form ref="Form" :model="Form" :rules="rules" label-width="130px">
              <el-form-item label="班级名称：" prop="projectName" class="form-item">
                <el-input v-model="Form.projectName" placeholder="请输入班级名称" :disabled="disabled" maxlength="50"
                  show-word-limit size="small" />
              </el-form-item>
              <el-form-item label="行政区划：" prop="areaId" class="form-item">
                <el-cascader v-model="Form.areaId" filterable :options="areatreeList" :props="propsarea"
                  :disabled="disabled" @change="areaChange" size="small"></el-cascader>
              </el-form-item>
              <!-- 只有上海才显示街道 - 这三个区必填，其余非必填 -->
              <el-form-item label="所属街道：" :prop="['310114', '310112', '310118'].includes(Form.areaId) ? 'street' : ''"
                class="form-item" v-if="zF.getString2(Form.areaId, 2) == '31'">
                <el-select size="small" v-model="Form.street" remote type="text" :remote-method="getStreetData" filterable
                  clearable placeholder="请选择街道">
                  <el-option v-for="item in streetData" :key="item.streetName" :label="item.streetName"
                    :value="item.streetName"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="有效日期：" prop="Effectivedate" class="form-item">
                <el-date-picker size="small" v-model="Form.Effectivedate" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp"></el-date-picker>
              </el-form-item>
              <el-form-item label="报名截止时间：" prop="deadline" class="form-item">
                <el-date-picker size="small" v-model="Form.deadline" type="date" format="yyyy-MM-dd"
                  value-format="timestamp" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="负责人：" prop="projectUser" class="form-item">
                <el-input v-model="Form.projectUser" placeholder="请输入负责人" :disabled="disabled" size="small" />
              </el-form-item>
              <el-form-item label="负责人电话：" prop="projectUserPhone" class="form-item">
                <el-input v-model="Form.projectUserPhone" placeholder="请输入负责人电话" maxlength="12" :disabled="disabled"
                  size="small" />
              </el-form-item>
              <el-form-item label="培训类型：" prop="Trainingtype" class="form-item">
                <tree ref="tree" @eventBtn="childBack" :ruleForm="Form" size="small" @clearParams="clearParams" modal type
                  typeStu :threePostsLimit="'1'" />
              </el-form-item>
              <el-form-item label="培训人数" prop="projectPeople" class="form-item" v-if="selectType != '0'" required>
                <el-input-number v-model="Form.projectPeople" @change="handleChange" :min="1" style="width: 100%"
                  :disabled="disabled" size="small"></el-input-number>
              </el-form-item>
              <el-form-item label="培训地点" prop="projectAddress" class="form-item" v-if="selectType != '0'">
                <el-input type="text" v-model="Form.projectAddress" :disabled="disabled" size="small"></el-input>
              </el-form-item>
              <el-form-item label="期次" prop="projectPc" class="form-item" v-if="selectType != '0'">
                <el-input type="text" v-model="Form.projectPc" :disabled="disabled" size="small"></el-input>
              </el-form-item>
              <el-form-item label="考试机构" prop="projectPaperComp" class="form-item" v-if="selectType != '0'">
                <el-input type="text" v-model="Form.projectPaperComp" :disabled="disabled" size="small"></el-input>
              </el-form-item>
              <el-form-item label="考试时间" prop="projectPaperDate" class="form-item" v-if="selectType != '0'">
                <el-date-picker v-model="Form.projectPaperDate" :disabled="disabled" type="date" format="yyyy-MM-dd"
                  value-format="timestamp" placeholder="选择日期" size="small"></el-date-picker>
              </el-form-item>
              <el-form-item label="人群类型" prop="projectCrowd" class="form-item" v-if="selectType == '4'">
                <el-select v-model="Form.projectCrowd" placeholder="请选择" :disabled="disabled" size="small">
                  <el-option v-for="item in CrowdtypeList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否班级补贴：" prop="isSubsidy" class="form-item">
                <el-select v-model="Form.isSubsidy" placeholder="请选择" size="small" filterable clearable>
                  <el-option v-for="item in SubsidyList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="项目编号：" prop="projectRecordno" class="form-item" :rules="[
                {
                  required: zF.getString2(Form.areaId, 2) == '31'&&params.trainFormId == '5' ? true : false,
                  message: '请输入项目编号',
                  trigger: 'blur',
                },
              ]">
                <el-input v-model="Form.projectRecordno" placeholder="请输入项目编号" :disabled="disabled" size="small"
                  maxlength="50" />
              </el-form-item>
              <el-form-item label="备案课时数：" prop="recordClasshours" class="form-item" :rules="[
                {
                  required:
                    ['3101', '3100'].includes(Form.areaId.slice(0, 4)) ||
                      (['130', '131', '139'].includes(Form.areaId.slice(0, 3)) &&
                        disableStu)
                      ? true
                      : false,
                  message: '请输入备案课时数',
                  trigger: 'change',
                },
              ]">
                <!-- <el-input
                  v-model="Form.recordClasshours"
                  placeholder="请输入备案课时数"
                  @keyup.native="
                    Form.recordClasshours = zF.oninput2(
                      Form.recordClasshours,
                      2
                    )
                  "
                  :disabled="disabled"
                  size="small"
                /> -->
                <el-input-number v-model="Form.recordClasshours" :disabled="disabled" :min="0" :max="999"
                  style="width: 100%" size="small"></el-input-number>
              </el-form-item>
              <el-form-item label="培训方式" prop="trainMethod" class="form-item"
                v-if="zF.getString2(Form.areaId, 2) == '41'">
                <el-select v-model="Form.trainMethod" placeholder="请选择" :disabled="disabled" size="small">
                  <el-option v-for="item in TrainingMethodsList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="申请表图片" prop="thumbnail" class="form-item" v-if="selectType != '0'">
                <el-upload class="upload-btndrag" :action="actionUrl" :on-error="handleError" :on-success="handleSuccess"
                  :on-change="uploadChange1" :show-file-list="false" :auto-upload="false" drag>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                  </div>
                </el-upload>
                <div v-if="Form.uploadfileList.length" style="margin-top: 10px; margin-left: 10px">
                  当前选择文件：
                  <div style="display: flex; flex-direction: column">
                    <span style="color: #409eff" v-for="(item, index) in Form.uploadfileList" :key="index">
                      {{ item.fileName }}
                      <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px">预览</a>
                      <a @click="reomveExlUpload(index)" style="color: red; margin: 0 15px">删除</a>
                    </span>
                  </div>
                </div>
                <div v-else>
                  <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
                </div>
              </el-form-item>
              <el-form-item label="上传附件" prop="fileName" class="form-item" v-if="Form.areaId.indexOf('620') != -1">
                <el-button class="bgc-bv" size="mini" @click="uploadAttachments">上传附件</el-button>
                <!-- <el-upload
                  class="upload-demo upload-btn"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange"
                  multiple
                   :file-list="uploadFileList"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <el-button class="bgc-bv" size="mini">上传附件</el-button>
                </el-upload>
                <span style="color: #f46173"
                  >支持上传word、excel、图片、pdf等，文件大小不超过1M。</span
                > -->
                <div style="display: flex; flex-direction: column">
                  <span style="color: #409eff" v-for="(item, index) in Form.chatFileKeys" :key="index">
                    {{ item.fileStyleValue }}
                    <a @click="downloadFile(item)" v-if="item.downloadUrl" style="color: #5bb5ff; margin: 0 15px">下载</a>
                    <a @click="reomveExl(index)" style="color: red; margin: 0 15px">删除</a>
                  </span>
                </div>
              </el-form-item>
            </el-form>
            <h3 style="padding-bottom: 20px">基本设置</h3>
            <div style="display: flex; flex-direction: column">
              <div>
                <span style="padding: 0 15px; width: 13rem; text-align: right">允许学员查看学时证明</span>
                <el-radio-group v-model="Form.projectClassHourCertificate">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </div>
              <div>
                <span style="padding: 15px 15px; width: 13rem; text-align: right">允许学员查看培训证书</span>
                <el-radio-group v-model="Form.studentCertPrint">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </div>
              <div v-if="Form.areaId && Form.areaId.substring(0, 3) == '310'">
                <span style="padding: 15px 15px; width: 13rem; text-align: right">课后练习开始考试后最短</span>
                <el-input-number v-model="Form.paraAnalysisLimitMinTime" size="small" :controls="false" :min="5" :step="1"
                  :precision="0"></el-input-number>
                分钟交卷
              </div>
              <div v-if="Form.areaId && Form.areaId.substring(0, 3) == '310'">
                <span style="padding: 15px 15px; width: 13rem; text-align: right">结业考试开始考试后最短</span>
                <el-input-number v-model="Form.paraExamLimitMinTime" size="small" :controls="false" :min="5" :step="1"
                  :precision="0"></el-input-number>
                分钟交卷
              </div>
              <div v-show="params.trainFormId == '11'">
                <span style="padding: 15px 15px; width: 13rem; text-align: right">是否开启问卷调查</span>
                <el-radio-group v-model="Form.enableSurvey">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
                <p style="padding-left: 211px">
                  开启问卷调查后,学员学完课程后会被随机抽取进行调查评问卷的填写，结果会记录在《综合考评报告》档案中。
                </p>
                <el-form ref="Form1" :model="Form1" :rules="rules1" label-width="130px" v-if="Form.enableSurvey">
                  <el-form-item label="抽查人数" prop="spotCheckNum" class="form-item">
                    <el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="Form1.spotCheckNum"
                      size="small"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>

            <div style="padding: 15px 0" v-show="Form.auditState == '40'">
              <h3 style="padding-bottom: 10px">审核事由</h3>
              <el-input type="textarea" disabled v-model="Form.auditReason"></el-input>
            </div>
            <div class="bottom-btn">
              <el-button @click="cancle" class="bgc-bv">取消</el-button>
              <el-button @click="seveData()" class="bgc-bv">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="上传文件" :visible.sync="uploadFileDialogVisible" width="30%" top="5%" :center="true"
      :before-close="closeDocsDialog">
      <div>
        <div class="ovy-a">
          <p style="color: #f46173">
            注:支持上传word、excel、图片、pdf、压缩包等，单个文件大小不超过5M。
          </p>
          <el-table ref="multipleTable" :data="tableDataFile" :height="tableHeight" size="small" tooltip-effect="dark"
            style="width: 100%" :header-cell-style="tableHeader" stripe>
            <el-table-column label="文件类型" align="center">
              <temaplate slot-scope="scope">
                <el-select v-model="scope.row.fileStyle" clearable placeholder="请选择" size="small"
                  @change="FileStyleChange">
                  <el-option v-for="item in ProjectFileStyleList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </temaplate>
            </el-table-column>
            <el-table-column label="上传文件" align="left" show-overflow-tooltip prop="projectCode">
              <template slot-scope="scope">
                <div style="display: flex">
                  <el-upload class="upload-demo upload-btn" :action="actionUrl" :on-error="handleError"
                    :on-success="handleSuccess" :on-change="uploadChange" multiple :file-list="uploadFileList"
                    :show-file-list="false" :auto-upload="false">
                    <el-button size="mini" type="text">上传文件</el-button>
                  </el-upload>
                  <el-button type="text" size="mini" :disabled="!Files.allow" style="padding: 0 5px"
                    @click="downloadFileTemplate(scope.$index, Files)">下载模版</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog title="预览" :visible.sync="previewLoding1" @close="uploadLodClose" width="50%" top="2%" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="fileType == 'pdf' || fileType == 'PDF'"></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "addClassCtr",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker, List],
  data() {
    var proTypeFrom = (rule, value, callback) => {
      var state = this.selectType;
      if (state == 2) {
        if (value == "") {
          callback(new Error("请选择人群类型"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入正确的负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的负责人电话"));
      } else {
        callback();
      }
    };

    return {
      previewLoding1: false,
      disabled: false,
      paperId: false,
      areatreeList: [],
      CrowdtypeList: [],
      props: {
        value: "id",
        label: "label",
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 街道下拉数据
      streetData: [],
      selectType: "0",
      Form: {
        projectId: "",
        projectName: "",
        areaId: "",
        street: "",
        Effectivedate: [],
        deadline: "",
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
        projectAddress: "",
        projectPc: "",
        projectPaperComp: "",
        Examinationtime: "",
        proType: "",
        thumbnail: "",
        fileKey: "",
        Trainingtype: "",
        projectClassHourCertificate: false,
        studentCertPrint: false,

        paraExamLimitMinTime: 5,
        paraAnalysisLimitMinTime: 5, // 课后练习开始考试后最短
        enableSurvey: false,
        auditState: "",
        auditReason: "",
        chatFileKeys: [],
        trainMethod: "",
        uploadfileList: [],
        projectRecordno: "", //项目编号
        recordClasshours: undefined, //备案课时数
        isSubsidy: true, //班级补贴
      },
      TrainingMethodsList: [],
      uploadFileList: [],
      Form1: {
        spotCheckNum: "",
      },
      params: {},
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        street: [
          { required: true, message: "请选择所属街道", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        trainMethod: [
          { required: true, message: "请选择培训方式", trigger: "change" },
        ],
        // projectRecordno: [
        //   { required: Form.areaId.indexOf("310") == -1 ?true :false, message: "请选择培训方式", trigger: "change" },
        // ],
      },
      rules1: {
        spotCheckNum: [
          { required: true, message: "请输入抽查人数", trigger: "blur" },
        ],
      },
      //上传文件
      uploadFileDialogVisible: false,
      tableDataFile: [],
      ProjectFileStyleList: [],
      Files: {},
      disableStu: false,
      trainTypeIds: [],
      SubsidyList: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ]
    };
  },
  created() {
    this.getCrowdtypeList();
    this.getareatree();
    this.getTrainTypeList2();
    console.log('projectSource', this.$route.query.projectSource);
  },
  methods: {
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
      if (this.trainTypeIds.indexOf(this.params.trainFormId) != -1) {
        this.disableStu = true;
      } else {
        this.disableStu = false;
      }
      console.log(this.disableStu)
    },
    clearParams() {
      this.params = {};
    },
    getCrowdtypeList() {
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      const ProjectFileStyle = this.$setDictionary(
        "PROJECT_FILE_STYLE",
        "list"
      );
      const ProjectTrainMethod = this.$setDictionary(
        "PROJECT_TRAIN_METHOD",
        "list"
      );
      const list = [],
        ProjectFileStyleList = [],
        ProjectTrainMethodList = [];
      for (const key in CrowdtypeList) {
        list.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      for (const key in ProjectFileStyle) {
        ProjectFileStyleList.push({
          value: key,
          label: ProjectFileStyle[key],
        });
      }
      for (const key in ProjectTrainMethod) {
        ProjectTrainMethodList.push({
          value: key,
          label: ProjectTrainMethod[key],
        });
      }
      this.CrowdtypeList = list;
      this.ProjectFileStyleList = ProjectFileStyleList;
      this.TrainingMethodsList = ProjectTrainMethodList;
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.thumbnail = result.data.fileURL;
            this.Form.fileKey = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    seveData(ruleForm) {
      const el = this.$refs.Form;
      const el1 = this.$refs.Form1;
      el.validate((valid) => {
        if (valid) {
          if (this.Form.enableSurvey && this.params.trainFormId == "11") {
            el1.validate((valid1) => {
              if (valid1) {
                this.doAjax();
                // if (!this.Form.fileKey) {
                //   this.$confirm("开班申请表未上传，是否上传？", "提示", {
                //     confirmButtonText: "是",
                //     cancelButtonText: "否",
                //     type: "warning",
                //   })
                //     .then(() => {})
                //     .catch(() => {
                //       this.doAjax();
                //     });
                // }
              }
            });
          } else if (
            !this.Form.enableSurvey &&
            this.params.trainFormId == "11"
          ) {
            this.doAjax();
            // if (!this.Form.fileKey) {
            //   this.$confirm("开班申请表未上传，是否上传？", "提示", {
            //     confirmButtonText: "是",
            //     cancelButtonText: "否",
            //     type: "warning",
            //   })
            //     .then(() => {})
            //     .catch(() => {
            //       this.doAjax();
            //     });
            // }
          } else if (
            this.Form.areaId.indexOf("130") != -1 &&
            this.params.trainFormId == "5"
          ) {
            if (this.Form.uploadfileList.length < 1) {
              this.$confirm("开班申请表未上传，是否上传？", "提示", {
                confirmButtonText: "是",
                cancelButtonText: "否",
                type: "warning",
              })
                .then(() => { })
                .catch(() => {
                  this.doAjax();
                });
            } else {
              this.doAjax();
            }
          } else {
            this.doAjax();
          }
        }
      });
    },
    FileStyleChange(val) {
      this.$post("/biz/project/template", { fileStyle: val }).then((ret) => {
        this.Files = ret.data;
      });
    },
    downloadFileTemplate(index, row) {
      window.open(row.downloadUrl);
    },
    // 上传图片
    uploadChange(file, fileList) {
      this.uploadFileList = fileList;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (size > 5) {
        this.$message.error("文件大小不能超过5M");
        this.handleError("上传失败", file, fileList);
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      let fileStyle = "";
      let fileStyleName = "";
      this.tableDataFile.forEach((element) => {
        let obj = {};
        obj = this.ProjectFileStyleList.find((item) => {
          return item.value === element.fileStyle;
        });
        fileStyle = element.fileStyle;
        fileStyleName = obj.label;
      });
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.chatFileKeys.push({
            fileKey: result.data.fileKey,
            fileName: file.name,
            fileType: extension,
            fileStyle,
            fileStyleValue: fileStyleName,
          });
        })
        .catch((err) => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    handleError(err, file, fileList) {
      let index = fileList.findIndex((el) => el.name == file.name);
      this.uploadFileList.splice(index, 1);
    },
    uploadAttachments() {
      this.uploadFileDialogVisible = true;
      this.tableDataFile = [{ fileStyle: "" }];
    },
    // 下载
    downloadFile(item) {
      const link = document.createElement("a");
      link.href = item.downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 删除
    reomveExl(index) {
      this.Form.chatFileKeys.splice(index, 1);
      this.uploadFileList.splice(index, 1);
      this.$forceUpdate();
    },
    // 街道
    getStreetData(val) {
      let cData = {
        areaId: this.Form.areaId
      }
      if (val) {
        cData.streetName = val;
      }
      this.$post("/sys/area/street", cData)
        .then((res) => {
          this.streetData = res.data;
        })
        .catch((err) => {
          console.log(err)
        });
    },
    // 添加
    doAjax() {
      let parmar = {
        projectName: this.Form.projectName,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        signEndDate: this.Form.deadline,
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        projectClassHourCertificate: this.Form.projectClassHourCertificate,
        trainMethod: this.Form.trainMethod,
        projectRecordno: this.Form.projectRecordno,
        recordClasshours: this.Form.recordClasshours,
        studentCertPrint: this.Form.studentCertPrint,
        isSubsidy: this.Form.isSubsidy,
      };
      if (this.Form.areaId.substring(0, 3) == '310' && !this.Form.paraExamLimitMinTime) {
        this.$message.error('请输入结业考试开始考试后最短交卷时间！')
        return
      } else if (this.Form.areaId.substring(0, 3) == '310' && this.Form.paraExamLimitMinTime) {
        parmar.paraExamLimitMinTime = this.Form.paraExamLimitMinTime;
      }
      if (this.Form.areaId.substring(0, 3) == '310' && !this.Form.paraAnalysisLimitMinTime) {
        this.$message.error('请输入课后练习开始考试后最短交卷时间！')
        return
      } else if (this.Form.areaId.substring(0, 3) == '310' && this.Form.paraAnalysisLimitMinTime) {
        parmar.paraAnalysisLimitMinTime = this.Form.paraAnalysisLimitMinTime;
      }
      if (this.Form.street) {
        parmar.street = this.Form.street;
      }
      if (this.params.trainFormId == "11") {
        parmar.enableSurvey = this.Form.enableSurvey;
        parmar.spotCheckNum = this.Form.enableSurvey
          ? this.Form1.spotCheckNum
          : 0;
        parmar.paperId = "1";
      }
      // if (this.Form.fileKey) {
      //   parmar.applicationForm = this.Form.fileKey;
      // }
      if (this.Form.projectAddress) {
        parmar.projectAddress = this.Form.projectAddress;
      }
      if (this.Form.projectCrowd) {
        parmar.projectCrowd = this.Form.projectCrowd;
      }
      if (this.Form.projectId) {
        parmar.projectId = this.Form.projectId;
      }
      if (this.Form.projectPc) {
        parmar.projectPc = this.Form.projectPc;
      }
      if (this.Form.projectPaperComp) {
        parmar.projectPaperComp = this.Form.projectPaperComp;
      }
      if (this.Form.projectPaperDate) {
        parmar.projectPaperDate = this.Form.projectPaperDate;
      }
      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      if (this.$route.query.projectSource) {
        parmar.projectSource = this.$route.query.projectSource;
      }
      if (this.Form.chatFileKeys.length > 0) {
        parmar.eduProjectFileList = this.Form.chatFileKeys;
      }
      if (this.Form.uploadfileList.length > 0) {
        parmar.applicationForm = this.Form.uploadfileList;
      }
      this.$post("/biz/project/saveOrUpdate", parmar)
        .then((ret) => {
          const name =
            this.$route.query.projectSource == 30
              ? "sjz/jg/classList"
              : "classListCtr";
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$router.push({
              name: name,
              params: {
                refresh: true,
                isAdd: true,
                addId: ret.data, //新班级Id，日报做处理
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancle() {
      this.$router.back();
    },
    areaChange(val) {
      console.log('val', val)
      this.Form.street = '';
      if (this.zF.getString2(val, 2) == '31') {
        this.getStreetData();
      }
      if (val.indexOf("41") == -1) {
        this.Form.trainMethod = "10";
      } else {
        this.Form.trainMethod = "";
      }
    },
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.Form.uploadfileList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    reomveExlUpload(index) {
      this.Form.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      console.log(item.fileUrl);
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      console.log("item.fileType", item.fileType);
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        console.log(1);
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    getTrainTypeList2() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          // this.trainTypeList = res.data;
          res.data.map((el) => {
            if (el.id == "4") {
              this.trainTypeIds.push(el.id);
              el.children.map((els) => {
                this.trainTypeIds.push(els.id);
                if (els.children) {
                  els.children.map((els1) => {
                    this.trainTypeIds.push(els1.id);
                  });
                }
              });
            }
          });
          if (this.trainTypeIds.indexOf(this.params.trainFormId) != -1) {
            this.disableStu = true;
          } else {
            this.disableStu = false;
          }
          // console.log(this.disableStu);
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    resize: none;
  }
}
</style>
<style lang="less">
.addClassCtr {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-item {
      width: 45%;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-demo {
    .el-upload {
      border: none !important;
      max-width: 5rem !important;
      height: 30px !important;
    }
  }
}
</style>
